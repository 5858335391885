@import "@v3/geko/src/less/variablesInternal.less";
@import "@v3/geko/src/less/variables.less";

@import "../../../common/src/less/genericOverrides.less";
@import "../../../common/src/less/variables.less";

@floater-desktop-width:				100px;
@floater-desktop-button-height:		100px;
@floater-desktop-offset-vertical:	3 * @floater-desktop-button-height + 30px;
@floater-start-bow-height: 16.5px; // Original image ratio adapted to @floater-desktop-width

@floater-mobile-height: 50px;
@floater-mobile-bow-width: 20px;
@floater-start-fs: @wp-fs-s;

.floater {
	z-index: 2;
}

.floater p {
	line-height: @line-height-m;
}

.floater p + p {
	margin-top: @padding-s;
}

.floater-buttonbar {
	background: @color-highlight;
}

.floater-starter-buttons {
	display: none;
}

.floater-start {
	color: @color-white;
	background: @color-brand-grey;
	text-align: center;
	position: relative;
}

.floater-start-text {
	font-size: 11.5px;
	font-weight: @font-weight-bold;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

.floater-button {
	color: @color-white;
	text-shadow: 1px 1px 2px #7b5500;
}

.floater-button.expanded {
	color: @color-body;
	text-shadow: none;
	background: @wp-orange-20;
}
.floater-dialog-wrapper {
	//position: absolute;
	//height: auto;
	transition: transform .3s ease;
	visibility: hidden;
}

.floater-inner.expanded .floater-dialog-wrapper {
	visibility: visible;
	padding: @padding-l;
	background: @color-white;
	overflow-y: auto;
	transition: transform .3s ease;
}

.floater-dialog-intro {
	line-height: @line-height-s;
	padding-bottom: @padding-m;
	border-bottom: 1px solid @color-brand-grey;
	margin-bottom: @padding-m;
}

.floater-headline {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

// override headline
.floater-headline .headline {
	margin-top: 0;
	margin-bottom: 0;
}

.floater-closebutton {
	font-size: @wp-fs-h3;
	color: @color-brand-grey;
}

.floater-closebutton:hover {
	color: @color-body;
}

.floater-p-emphasis {
	font-weight: @font-weight-bold;
}

.floater-image {
	width: 100%;
	filter: drop-shadow(5px 5px 10px rgba(36, 40, 42, 0.2));
	border: 1px solid @wp-grey-20;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.floater {
		position: sticky;
		top: @floater-desktop-offset-vertical + 50px;
	}

	.floater-inner {
		position: absolute;
		top: -@floater-desktop-offset-vertical;
		left: 0;
		width: @floater-desktop-width;
	}

	.floater-buttonbar,
	.floater-dialog-wrapper {
		filter: @filter-drop-shadow;
	}

	.floater-start {
		padding: @padding-s @padding-xs;
	}

	.floater-start:after {
		content: '';
		position: absolute;
		bottom: -@floater-start-bow-height;
		left: 0;
		width: @floater-desktop-width;
		height: @floater-start-bow-height;
		background-image: url('/img/wp/schwung-1200x132-brandgrey.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}

	.floater-button {
		width: 100%;
		height: @floater-desktop-button-height;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
	}

	.floater-button:not(:last-child) {
		border-bottom: 2px solid @color-white;
	}

	.floater-button .fa-light {
		font-size: 40px;
		margin-bottom: @padding-s;
	}

	.floater-button-text {
		text-align: center;
		font-size: 14px;
	}

	.floater-dialog-wrapper {
		transform: scaleX(0);
		transform-origin: left;
		display: block;
		position: absolute;
		top:0;
		left: @floater-desktop-width;
	}
	.floater-inner.expanded.attached .floater-dialog-wrapper {
		transform: scaleX(1);
		width: @content-width - @floater-desktop-width - @padding-l;
	}
	.floater-inner.expanded.centered .floater-dialog-wrapper {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	.floater-inner.expanded.centered .floater-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: @color-modal-backdrop-bg;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.floater-headline {
		margin-bottom: @padding-m;
	}

	.floater-p-wrapper {
		margin: @padding-s @padding-m @padding-l @padding-m;
	}
	.floater-columns {
		display: flex;
	}
	.floater-columns-mobile {
		display: none;
	}
	.floater-col-1 {
		flex-grow: 1;
		margin-right: @padding-l;
	}
	.floater-col-2 {
		flex-shrink: 0;
		width: 40%;
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.floater {
		position: fixed;
		width: 100%;
		height: @floater-mobile-height;
		top: 0;
	}

	.floater-inner,
	.floater-buttonbar,
	.floater-button {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		height: 100%;
	}

	.floater-inner,
	.floater-buttonbar {
		width: 100%;
	}

	.floater-buttonbar {
		z-index: 3;
		box-shadow: 4px 4px 8px rgba(0,0,0,0.3);
	}

	.floater-start {
		flex-grow: 0;
		flex-shrink: 0;
		padding: ((@floater-mobile-height - @floater-start-fs) / 2);
		height: 100%;
		margin-right: @floater-mobile-bow-width;
	}

	.floater-start:after {
		content: '';
		position: absolute;
		top: 0;
		right: -@floater-mobile-bow-width;
		width: @floater-mobile-bow-width;
		height: @floater-mobile-height;
		background-image: url('/img/wp/schwung-20x50-brandgrey.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}

	.floater-button:not(:last-child) {
		border-right: 2px solid @color-white;
	}

	.floater-button {
		padding: @padding-s;
		flex-grow: 1;
		flex-shrink: 0;
	}

	.floater-button .fa-light {
		margin-right: @padding-s;
		font-size: 30px;
	}

	.floater-button-text-1 {
		font-weight: @font-weight-bold;
	}

	.floater-dialog-wrapper {
		transform: scaleY(0);
		transform-origin: top;
		display: block;
		position: fixed;
		top: @floater-mobile-height;
		left: 0;
		right: 0;
	}
	.floater-inner.expanded .floater-dialog-wrapper {
		transform: scaleY(1);
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.floater-headline {
		margin-bottom: @padding-s;
	}

	.floater-button .fa-light {
		display: none;
	}
	.floater-start {
		padding-top: ((@floater-mobile-height - @floater-start-fs) / 2);
		padding-bottom: ((@floater-mobile-height - @floater-start-fs) / 2);
		padding-left: @padding-xs;
		padding-right: @padding-xs;
		margin-right: 10px;
	}

	// make dialogs fullscreen on mobile
	.floater-dialog-wrapper {
		bottom: 0;
	}

	.floater-start-text {
		display: none;
	}
	.floater-button-text {
		font-size: @floater-start-fs;
	}

	.floater-p-wrapper {
		margin: @padding-l 0;
	}
	.floater-columns {
		display: none;
	}
	.floater-image-mobile {
		float: right;
		width: 35%;
		border: 3px solid black;
		border-radius: @border-radius-l;
		transform: rotate(10deg);
		margin-left: @padding-s;
		margin-bottom: @padding-m;
		filter: drop-shadow(5px 5px 10px rgba(36, 40, 42, 0.2));
	}
	.floater-p-wrapper-mobile {
		padding: 15% 0;
	}
	.floater-button-bar {
		clear: both;
		display: flex;
		justify-content: center;
	}
	.floater-button-bar .primary-button {
		max-width: 300px;
	}
}
