@color-primary:					@color-highlight;
@color-primary-dark:			@color-highlight-hover;

//-----------------------------------------------------------------------------
// decor.less

.Decor--style-primary,
.Decor--style-secondary {
	border-radius: 10000px;
}

.Decor--style-primary {
	font-weight: @font-weight-bold;
	text-shadow: @text-shadow;
}

.interactive:disabled .Decor--style-primary {
	text-shadow: none;
}

.Decor--style-secondary {
    color: @ghost-button-color;
    border: 2px solid @ghost-button-color;
}

.Decor--style-link.Decor--layout-always-label {
	text-decoration: underline;
}

.Decor--style-link,
.Decor--style-hoverinvert {
	color: @ghost-button-color;
}

/*
// currently unused
.Decor--style-secondary.Decor--size-small {
	border: 1px solid @ghost-button-color;
}
*/

.interactive:where(a, button:enabled):hover .Decor--style-secondary,
.interactive:where(a, button:enabled):hover .Decor--style-secondary {
	background-color: @ghost-button-color;
	border: 2px solid @ghost-button-color;
	color: @color-white;
}

.interactive:where(a, button:enabled):hover .Decor--style-link {
	color: @color-highlight-text-hover;
}

/*
// currently unused
.interactive:where(a, button:enabled):hover .Decor--style-secondary.Decor--size-small {
	border: 1px solid @ghost-button-color;
}
*/

//-----------------------------------------------------------------------------
// inputs.less

.input-label {
	font-weight: @font-weight-bold;
	color: @color-body;
}

.input-textarea {
	height: 160px;
}

.input-counter-button:hover,
.input-counter-button:active {
	text-shadow: @text-shadow;
}
