// -------------------------------------------------------------------------------
// Colors
// -------------------------------------------------------------------------------

// Deprecated: These should not be used anymore
@color-grey1: #f2f2f2; // buchung and up
@color-grey2: #e7e8e3; // buchung and up
@color-grey4: #9f9c93; // up
@color-grey5: #7d7b74; // buchung and up

// -------------------------------------------------------------------------------
// New Colors for Wordpress Theme
// -------------------------------------------------------------------------------

@wp-orange-95:	#996a00; // text shadow for white oh yellow bg
@wp-orange-90:	#c46a00;
@wp-orange-85:	#f8a700;
@wp-orange-80:	#f9b317;

//@wp-orange-70: #fabb00;
//@wp-orange-60: #fbc526;
//@wp-orange-50: #fbcf4d;
@wp-orange-40: #fcda73;
//@wp-orange-30: #fde499;
@wp-orange-20: #feeebf;
//@wp-orange-10: #fff8e5;

//@grey-80: #757361;
@wp-grey-80: #918e7a;
//@wp-grey-70: #92907b;
//@wp-grey-60: #a2a18f;
//@wp-grey-50: #b3b1a3;
//@wp-grey-40: #c3c2b6;
//@wp-grey-30: #cdcec5;
@wp-grey-20: #dfe0db;
@wp-grey-10: #f2f3f0;
@wp-grey-05: #f7f8f6; // background in concept

@color-red:		#b50000;
@color-lila:	#5d2b6c;
@color-blue-80:		#074682;	// Geotoura Newsletter Blue
@color-blue-70:		#475ea8;	// Geotoura Italia Blue
@color-green-80:	#075e2a;
@color-green-70:	#075d2a;
// Common colors, these can and should be used directly in projects

@color-body:					#24282a;
@color-white:					#ffffff;
@color-highlight:				@wp-orange-80;
@color-highlight-hover:			@wp-orange-85;
@color-highlight-text-hover:	@wp-orange-90;
@color-hightlight-bg-hover:		@wp-orange-40;
@color-highlight-text-shadow:	@wp-orange-95;
@color-highlight-bright:		@wp-orange-20; // Buchung only

@color-brand-grey:				@wp-grey-80; // aka matschepampe
@color-error:					@color-red;
@color-focus:					@color-lila;
@color-signature:				@color-blue-70;

// -------------------------------------------------------------------------------
// Colors by Function
// -------------------------------------------------------------------------------

// common form layout in form.less
@field-control-border-color:		#b1af9b;
@field-control-box-shadow:			inset 1px 1px 2px 0 #f0f0f0;

// buchung
@bk-box-header-bg:	@color-grey2;
@bk-box-content-bg:	@color-grey1;

// OLD Up hotel colors,
// kept around in case four sets of balanced colors with matching hover colors are needed
/*
@color-encantador: #DF5F44;
@color-encantador-hover: #C4533C;
@color-especial: #A5986C;
@color-especial-hover: #897E5A;
@color-extraordinario: #D0A12D;
@color-extraordinario-hover: #B58A27;
@color-lunademiel: #F7DE50;
@color-lunademiel-hover: #DBC548;
*/

// -------------------------------------------------------------------------------
// New Wordpress Theme
// -------------------------------------------------------------------------------

@wp-concept-bg-color:			@wp-grey-05;
@wp-concept-bg-color-hover:		@wp-grey-10;
@wp-nav-menu-active-bg-color:	@wp-grey-10;
@wp-nav-menu-border:			@wp-grey-20;

// -------------------------------------------------------------------------------
// Fragebogen colors
// -------------------------------------------------------------------------------

@fb-grey-01:	rgba(122, 122, 122, 1.0);
@fb-grey-02:	rgba(150, 150, 150, 1.0);
@fb-grey-03:	rgba(146, 144, 123, 1.0);
@fb-grey-04:	rgba(232,232,232,1.0);
@fb-grey-05:	rgba(83, 83, 83, 1.0);
@fb-grey-06:	rgba(212, 212, 212, 1.0);

//-------------------------------------------------------------------------------
// UP

@color-text-light:			#b4b0a2;
@ghost-button-color:		#535353;

@destinationinfo-added-bg:	@color-grey2;
@hotel-featured-bg:			@color-grey2;

@requestroute-bg:			#e7e8e3;

@app-content-toggle-bg:		@route-bg;
@app-content-toggle-bg-2:	#d8d8d8;

@app-map-boxshadow:		-4px -4px 4px rgba(0, 0, 0, .2);
@app-map-gradient:		linear-gradient(to left, rgba(0,0,0,.2), transparent);

@region-gradient:		linear-gradient(16deg, #383838, #A4a4a4 15%, #A4a4a4);
@region-item-bg:		rgba(255, 255, 255, .7);

@route-bg:				#a4a4a4;
@route-summary-bg:		#f1f1f1;
@route-counter-bg:		#3e4954;

@routestop-icon-opacity:	0.5;
@routestop-bg:				@route-summary-bg;
@routestop-active-bg:		#d3d3d3;
@routestop-border-color:	#e2e2e2;
@routestop-icon-color:		@route-bg;

@routebar-request-bg:		@color-highlight;
@routebar-request-bg-hover: @color-highlight-hover;

@requestroute-line-color:	@color-text-light;

@infotitle-icon-color:		@color-text-light;

@lightbox-backdrop-color:		rgba(64, 73, 81, .8);
@lightbox-button-color:			rgba(255,255,255,.8);
@lightbox-button-color-hover:	rgb(64, 73, 81);

@gallery-button-color:			rgba(255,255,255,.8);
@gallery-button-color-hover:	rgba(0,0,0,.5);

@arrowbox-boxshadow: 2px 2px 3px rgba(0, 0, 0, 0.3);

@step-color:			@color-grey5;

@search-result-bg:		@color-grey1;
@search-result-border:	#f1ede7;

@loading-spinner-color: @color-grey-70;

// -------------------------------------------------------------------------------
// Shadows and Fades
// -------------------------------------------------------------------------------

@text-shadow:			1px 1px 2px @color-highlight-text-shadow;
@text-shadow-white:		drop-shadow(2px 4px 6px black);
@box-shadow:			0 0 4px 0 rgba(0, 0, 0, 0.3); // glow around
@box-drop-shadow:		3px 3px 4px rgba(0,0,0,.5);	// used for wp action-formbox
@focus-outline:			0 0 4px 2px @color-focus;

@filter-drop-shadow:	drop-shadow(@box-drop-shadow);

// Use as filter: @button-shadow
@button-shadow:			drop-shadow(0px 10px 20px rgba(36, 40, 42, 0.2));
@button-shadow-mobile:	drop-shadow(0px 5px 10px rgba(36, 40, 42, 0.2));

.fade-to-white {
	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 40px;
		background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) );
	}
}

// -------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------

@font-body:			'Asap', sans-serif;
@font-handdrawn:	'Nighttime', sans-serif;
// NOTE Nighttime needs letterspacing because of broken kerning, especially with Umlauts
@font-handdrawn-letterspacing: .03em;
@font-handdrawn-line-height: 0.9;

// TODO css check usage and if it can be replaced by new fancy Nighttime font,
// will be probably still needed in fb counters
@font-fancy:		'Georgia', serif;

@fa-font-path: "/fonts/fontawesome/";

// TODO css unify font sizes
// Modular scale 1.25
@wp-fs-base:		16px;

// 76.294px, 95.367px
@wp-fs-h0:			61.035px;
@wp-fs-h1:			48.828px;
@wp-fs-h2:			39.063px;
@wp-fs-h3:			31.25px;
@wp-fs-h4:			25px;
@wp-fs-h5:			20px;
@wp-fs-h6:			@wp-fs-base;
@wp-fs-s:			12.8px;
@wp-fs-xs:			10.24px;

// Modular scale 1.2
@wp-fs-tablet-base:	16px;

// 57.331px, 68.797px, 82.556px
@wp-fs-tablet-h0:	47.776px;
@wp-fs-tablet-h1:	39.813px;
@wp-fs-tablet-h2:	33.178px;
@wp-fs-tablet-h3:	27.648px;
@wp-fs-tablet-h4:	23.04px;
@wp-fs-tablet-h5:	19.2px;
@wp-fs-tablet-h6:	@wp-fs-tablet-base;
@wp-fs-tablet-s:	13.333px;
@wp-fs-tablet-xs:	11.111px;

// Modular scale 1.067
@wp-fs-mobile-base:	16px;

// 42.324px, 39.666px, 37.176px, 34.841px,
// 32.653px, 30.603px, 28.681px, 26.88px
@wp-fs-mobile-h0:	23.611px;
@wp-fs-mobile-h1:	22.128px;
@wp-fs-mobile-h2:	20.739px;
@wp-fs-mobile-h3:	19.436px;
@wp-fs-mobile-h4:	18.216px;
@wp-fs-mobile-h5:	17.072px;
@wp-fs-mobile-h6:	@wp-fs-mobile-base;
@wp-fs-mobile-s:	14.995px;
@wp-fs-mobile-xs:	14.054px;

//-------------------------------------------------------------------------------
// fb font sizes

// TODO css unify font sizes
@fb-fs-radiobutton-text:	26px;
@fb-fs-radiobutton-icon:	46px;
@fb-fs-listitem:			22px;
@fb-fs-heading:				18px;

// NOTE line-height 1 should be the default to avoid complications with line-heights, paddings and margins adding up.
// Larger lineheights should only be used for text paragraphs

@line-height:	1.45;
@line-height-m: 1.35;
@line-height-s: 1.25;
@line-height-xs: 1.1;

// Desktop
.fs-1 {	font-size: @wp-fs-h1; }
.fs-2 {	font-size: @wp-fs-h2; }
.fs-3 { font-size: @wp-fs-h3; }
.fs-4 {	font-size: @wp-fs-h4; }
.fs-5 {	font-size: @wp-fs-h5; }
.fs-6 {	font-size: @wp-fs-h6; }
.fs-s {	font-size: @wp-fs-s; }
.fs-xs { font-size: @wp-fs-xs; }

// Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.fs-1 { font-size: @wp-fs-tablet-h1; }
	.fs-2 {	font-size: @wp-fs-tablet-h2; }
	.fs-3 {	font-size: @wp-fs-tablet-h3; }
	.fs-4 {	font-size: @wp-fs-tablet-h4; }
	.fs-5 {	font-size: @wp-fs-tablet-h5; }
	.fs-6 { font-size: @wp-fs-tablet-h6; }
	.fs-s {	font-size: @wp-fs-tablet-s; }
	.fs-xs { font-size: @wp-fs-tablet-xs; }
}

// Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.fs-1 { font-size: @wp-fs-mobile-h1; }
	.fs-2 {	font-size: @wp-fs-mobile-h2; }
	.fs-3 {	font-size: @wp-fs-mobile-h3;}
	.fs-4 {	font-size: @wp-fs-mobile-h4; }
	.fs-5 {	font-size: @wp-fs-mobile-h5; }
	.fs-6 {	font-size: @wp-fs-mobile-h6; }
	.fs-s {	font-size: @wp-fs-mobile-s; }
	.fs-xs { font-size: @wp-fs-mobile-xs; }
}

// -------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------

@content-width:				980px;
@hero-desktop-height:		600px;
@hero-mobile-height:		300px;

@footer-mobile-navi-offset: 130px;
@nav-desktop-height:		100px;
@nav-mobile-height:			50px;
@nav-desktop-menu-width:	220px;
@nav-submenu-button-width:	30px;
@header-desktop-height:		50px;
@intro-image-height:		500px;

@main-bottom-margin-desktop:		90px;
@main-bottom-margin-tablet-mobile:	60px;

@questionnaire-width:			420px;
@routebox-desktop-width:		420px;
@territorybox-desktop-height:	640px;
@routebox-tablet-width:			250px;
@territorybox-tablet-height:	400px;

@routegallery-image-width:		460px;

@max-line-length-desktop: 70%;

// Used for popups and partner elements
@width-xs:		200px;
@height-xs:		120px;

@offer-desktop-tablet-height:	80px;
@offer-mobile-inner-height:		76px;
@offer-step-mobile-height:		30px;
@offer-mobile-height:			@offer-mobile-inner-height + @offer-step-mobile-height;
